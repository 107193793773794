import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const RezydencjaGubalowka = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "Rezydencja Gubałówka",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #00425B, #014D6A, #005C80, #014D6A, #00425B )",
        icons: "#015576",
        navClass: "gubalowka",
        ogImage: require("../../../assets/img/portfolio/gubalowka_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/rezydencja-gubalowka/",
      }}
    >
      <PortfolioHeader name="gubalowka" height="135" />
      <section className="container-fluid gubalowka_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Rezydencja Gubałówka</h1>
              <ul>
                <li>Website</li>
                <li>Lead Generation Campaign </li>
                <li>Advertising banners</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Our job was to create an attractive project supporting sales and
                presenting the investment offer of luxury apartments for sale in
                the Gubałówka Residence. The project was to evoke specific
                emotions and increase the desire in the potential buyers to make
                investments, by emphasizing the prestige of the place and
                attractive conditions of cooperation. The website was to be
                visually consistent with the existing visual identity of the
                Residence - in this way we created a coherent image of the
                Client, strengthened messages in the minds of recipients and
                created links between all elements of the advertising.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid gubalowka_section_3">
        <div className="row align-items-center row_1">
          <div className="col-md-6">
            <p>
              We have created a Landing Page to support the sale of luxurious
              apartments - the extension of an already existing and operating
              for over 2 years hotel facility in the heart of Zakopane, in the
              Polish mountains. Our goal was to show not only the potential
              profits for the investors, but also the attractiveness of the
              location of the hotel on Gubałówka, in Poland..
            </p>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/gubalowka_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-7">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/gubalowka_main_1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-7 offset-md-5 text-right">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/gubalowka_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters align-items-end row_4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/gubalowka_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              Based on the website we created and a set of marketing banners, we
              ran an extensive marketing campaign in social media, i.e. Facebook
              and Google, obtaining excellent results, translating into a very
              high number of leads and a record low cost of acquiring them, and
              thus resulting both our and our Client’s complete satisfaction. It
              is worth to mention that our Client was already planning on
              strengthening our cooperation and jointly implement subsequent
              realization of development projects, and the success of this
              project enhanced that even more.
            </p>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default RezydencjaGubalowka;
